<template>
  <div class="position:relative;">
      <router-link :to="{path: '/login' }" class="btn btn-dark " style="position:absolute; top:20px; right:20px;">Back to Login</router-link>
    <div class="nk-content ">
      <div class="container-fluid">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="content-page wide-md m-auto">
              <div class="nk-block-head nk-block-head-lg wide-sm mx-auto">
                <div class="nk-block-head-content text-center">
                  <h2 class="nk-block-title fw-normal">Help &amp; Support</h2>
                  <div class="nk-block-des">
                    <p class="lead">
                     Our help section is currently under development and will be available shortly. In the meantime contact us for any support.
                    </p>
                    <p class="text-soft ff-italic">Last Update: April 08 2020</p>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="card card-bordered">
                  <div class="card-inner card-inner-xl">
                    <div class="entry">
                      <h3>Coming soon</h3>
                      <p>
                       More here shortly.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
methods:{
  getView (){ this.$http.get('/ViewAccess/help')
        .then(() => { 
        })
        .catch(() => { 
        })},
},
created(){
  this.getView()
}

};
</script>

<style></style>
